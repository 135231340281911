.layout-container {
    &.layout-slim-plus {
        .layout-topbar {
            .app-logo {
                width: 12rem;
                img {
                    width: 6rem;
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-slim-plus {
            .layout-sidebar {
                width: 14rem;
            }

            .layout-content-wrapper {
                margin-left: 14rem;
            }

            .layout-menu {
                .layout-root-menuitem {
                    > a {
                        width: 12rem;
                        .layout-menuitem-text {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
