@media screen and (min-width: 576px) {
    .sm-w-33rem {
        width: 33rem !important;
    }
}

/* ================== */

.otp-center-input {
    &.p-inputtext {
        text-align: center;
    }
}
.divider-cm > .p-divider {
    margin: 0.75rem 0;
}

.divider-13 {
    .p-divider {
        margin: 0.75rem 0rem 1.15rem 0;
    }

    .p-divider.p-divider-horizontal:before {
        border-top: 7px #dee2e6;
        border-bottom: 6px #dee2e6;
    }

    .p-divider.p-divider-horizontal:before {
        border-top-style: solid;
        border-bottom-style: solid;
    }

    &.divider-success {
        .p-divider.p-divider-horizontal:before {
            border-top: 7px var(--green-500);
            border-bottom: 6px var(--green-500);
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }

    &.divider-blue {
        .p-divider.p-divider-horizontal:before {
            border-top: 7px var(--blue-500);
            border-bottom: 6px var(--blue-500);
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }

    &.divider-warning {
        .p-divider.p-divider-horizontal:before {
            border-top: 7px var(--yellow-500);
            border-bottom: 6px var(--yellow-500);
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }
}

.divider-6 {
    .p-divider {
        margin: 0.75rem 0rem 1.15rem 0;
    }

    .p-divider.p-divider-horizontal:before {
        border-top: 3px #dee2e6;
        border-bottom: 3px #dee2e6;
    }

    .p-divider.p-divider-horizontal:before {
        border-top-style: solid;
        border-bottom-style: solid;
    }
}

.divider-3 {
    .p-divider {
        margin: 0.75rem 0rem 1.15rem 0;
    }

    .p-divider.p-divider-horizontal:before {
        border-top: 2px #fff;
        border-bottom: 1.5px #fff;
    }

    .p-divider.p-divider-horizontal:before {
        border-top-style: solid;
        border-bottom-style: solid;
    }

    &.divider-dark {
        .p-divider.p-divider-horizontal:before {
            border-top: 2px #dee2e6;
            border-bottom: 1.5px #dee2e6;
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }

    &.divider-blue {
        .p-divider.p-divider-horizontal:before {
            border-top: 2px var(--blue-500);
            border-bottom: 1.5px var(--blue-500);
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }
}

.divider-1 {
    .p-divider {
        margin: 0.75rem 0rem 1.15rem 0;
    }

    .p-divider.p-divider-horizontal:before {
        border-top: 1px #fff;
        border-bottom: 1px #fff;
    }

    .p-divider.p-divider-horizontal:before {
        border-top-style: solid;
        border-bottom-style: solid;
    }

    &.divider-dark {
        .p-divider.p-divider-horizontal:before {
            border-top: 1px var(--text-color);
            border-bottom: 1px var(--text-color);
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }

    &.divider-light {
        .p-divider.p-divider-horizontal:before {
            border-top: 1px #dee2e6;
            border-bottom: 1px #dee2e6;
        }

        .p-divider.p-divider-horizontal:before {
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }
}

.divider-sidemenu {
    .p-divider.p-divider-horizontal:before {
        left: 15%;
        width: 70%;
    }
}

.custom-p-table {
    .p-datatable-header {
        padding: 0;
    }
}

/* 
====
==== AVATAR WITH CUSTOM COLOR ====
====
*/
.avatar-event {
    --_background-color: var(--background-color, rgba(101, 214, 173, 0.1));
    --_color: var(--color, #27ab83);
    --_border: var(--border, 1px solid #65d6ad);

    .p-avatar-circle {
        background-color: var(--_background-color);
        color: var(--_color);
        border: var(--_border);
    }
}

.avatar--danger {
    --_background-color: rgba(255, 155, 155, 0.1);
    --_color: #cf1124;
    --_border: 1px solid #ff9b9b;
}

.avatar--info {
    --_background-color: rgba(94, 208, 250, 0.1);
    --_color: #1992d4;
    --_border: 1px solid #5ed0fa;
}

.avatar--success {
    --_background-color: rgba(101, 214, 173, 0.1);
    --_color: #27ab83;
    --_border: 1px solid #65d6ad;
}

.avatar--warning {
    --_background-color: rgba(250, 219, 95, 0.1);
    --_color: #de911d;
    --_border: 1px solid #fadb5f;
}

/* 
====
==== CARDS WITH CUSTOM BORDERS DINAMIC ====
====
*/

.card-w-border {
    --_background-color: var(--background-color, var(--primary-color));
    .border {
        position: absolute;
        border-radius: 3px;
        background: var(--_background-color);
        &.b--l {
            height: 100%;
            width: 9px;
            left: 0;
            top: 0;
        }
        &.b--t {
            height: 9px;
            width: 100%;
            left: 0;
            top: 0;
        }

        &.b--r {
            height: 100%;
            width: 9px;
            top: 0;
            right: 0;
        }

        &.b--b {
            height: 9px;
            width: 100%;
            left: 0;
            bottom: 0;
        }
        &.border--danger {
            --_background-color: var(--red-500);
        }
        &.border--info {
            --_background-color: var(--blue-500);
        }
        &.border--secondary {
            --_background-color: var(--bluegray-500);
        }
        &.border--success {
            --_background-color: var(--green-500);
        }
        &.border--help {
            --_background-color: var(--purple-500);
        }
        &.border--warning {
            --_background-color: var(--yellow-500);
        }
    }
}

/* 
====
==== PANELWITH CUSTOM HEADER ====
====
*/

.panel-header-bg {
    --_background-color: var(--background-color, var(--primary-color));
    --_color: var(--color, var(--primary-color-text));
    .p-panel-header {
        background: var(--_background-color);
        color: var(--_color);
    }

    &.header-secondary {
        --_background-color: var(--blue-200);
        --_color: var(--text-color);
    }
    &.header-warning {
        --_background-color: var(--yellow-600);
        --_color: var(--surface-0);
    }
    &.header-warning-soft {
        --_background-color: var(--yellow-300);
        --_color: var(--text-color);
    }
}

/* 
====
==== STEPS RESPONSIVE & CUSTOM COLORS ====
====
*/

.steps-responsive {
    ul {
        flex-wrap: wrap;
    }
    li {
        cursor: pointer;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: var(--primary-color);
        color: var(--primary-color-text);
    }

    &.severity-warning {
        .p-steps .p-steps-item.p-highlight .p-steps-number {
            background: var(--yellow-500);
            color: var(--primary-color-text);
        }
    }
}

/* 
====
==== MISC STYLES ====
====
*/
.display-grid {
    display: grid;
}

.bg-surface-hover {
    background: #dfe7ef;
}

.bg-surface-d {
    background: var(--surface-d);
}

.contain-content {
    contain: content;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.bg-transparent {
    background: transparent !important;
    background-color: transparent !important;
}

.justify-content-space-between {
    justify-content: space-between !important;
}

.word-wrap-break-word {
    word-wrap: break-word !important;
}

.white-space-pre-line {
    white-space: pre-line !important;
}

/*  */

/* 
====
==== MISC STYLES ====
====
*/
.tabs-menu-settings {
    padding: 0.25rem;

    .p-tabmenu-nav-container {
        border-radius: 14px;
    }

    ul {
        border: none;

        .p-menuitem-link {
            font-size: 0.8rem;
            padding: 1rem 1rem;
            border: none;
        }
    }
}

/*  */

.badge {
    top: 0;
    right: 0;

    .p-badge {
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.8rem;
        height: 1.8rem;
        line-height: 1.9rem;
        border-radius: 50% !important;
    }
}

.card-legal-representative {
    .p-card .p-card-footer {
        display: flex;
        justify-content: center;
        padding-top: 0;
    }
}

.p-chips-legal-representative {
    .p-chips > .p-inputtext {
        width: 100%;
    }

    .p-chips {
        width: 100%;
    }
}

.border-dashed-success {
    border-style: dashed;
    border-color: var(--green-500);
    border-radius: 14px;
}

.border-dashed-blue {
    border-style: dashed;
    border-color: var(--blue-500);
    border-radius: 14px;
}

.accordion-tab-claimant {
    .p-accordion-header-text {
        color: var(--green-500);
    }
}

.accordion-tab-respondent {
    .p-accordion-header-text {
        color: var(--blue-500);
    }
}

.accordion-tab-evidence-claimant {
    background: var(--green-500);
    .p-accordion-toggle-icon {
        color: white;
    }
    .p-accordion-header-text {
        color: white;
        text-align: center;
    }
}

.accordion-tab-evidence {
    background: var(--primary-color);
    .p-accordion-toggle-icon {
        color: white;
    }
    .p-accordion-header-text {
        color: white;
        text-align: center;
    }
}

.custom-modal {
    .p-dialog-footer {
        text-align: center;
    }
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-10 {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

#chatWidget {
    position: relative !important;
    z-index: 999 !important;
}

.card-representative {
    .p-card-body {
        height: 100%;
        .p-card-content {
            height: 88%; //Change to Hide Bio
            // height: 92%;
        }
    }
}

.float-right {
    float: right !important;
}

.fc-event {
    cursor: pointer;
}

.fc-theme-standard td,
.fc-theme-standard th {
    background-color: white;
}

.fc-col-header-cell {
    color: #8697a8;
    font-weight: 500;
    padding: 0.5rem 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #f8fafb;

    a.fc-daygrid-day-number {
        background: #0081ff33;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
    }
}

.overlayPanelEvent .p-overlaypanel-content {
    padding: 0;
}

.overlayPanelEvent.p-overlaypanel:before,
.overlayPanelEvent.p-overlaypanel:after {
    content: none;
}

.modal-settlement-term {
    .p-dialog-header {
        background: #dfe7ef;
    }

    .p-dialog-content {
        background: #dfe7ef;
    }
}

.modal-update-dispute-file-number {
    @media (768px <= width <= 991px) {
        &.p-dialog {
            width: 60% !important;
        }
    }
    @media (576px <= width <= 768px) {
        &.p-dialog {
            width: 80% !important;
        }
    }
    @media (width < 576px) {
        &.p-dialog {
            width: 90% !important;
        }
    }
    .p-dialog-header {
        background: #dfe7ef;
    }

    .p-dialog-content {
        background: #dfe7ef;
    }
}

.buttons-select {
    &.p-selectbutton {
        .p-button {
            border-radius: 6px;
        }
        .p-button:not(:last-child) {
            border-right: 1px solid #ced4da;
            margin-right: 3rem;
        }
    }
}

.markdown-component {
    table {
        border-spacing: 0;
        border-collapse: collapse;
        display: block;
        width: max-content;
        max-width: 100%;
        overflow: auto;
        margin-top: 0;
        margin-bottom: 24px;

        tr {
            background-color: #ffffff;
            border-top: 1px solid #d0d7deb3;
        }

        th {
            font-weight: 600;
        }

        th,
        td {
            padding: 6px 13px;
            border: 1px solid #d0d7de;
        }

        tr:nth-child(2n) {
            background-color: #f6f8fa;
        }
    }
}
