@media screen and (max-width: $breakpoint) {
    .layout-footer {
        height: auto;
        z-index: 0;
        padding: 1rem 0;
        position: relative;
        color: var(--text-color);
    }
}

@media screen and (max-width: $breakpoint - 1) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-topbar {
        .topbar-menubutton {
            background: var(--primary-700);
            &:hover {
                background-color: var(--primary-800);
            }
        }
    }
}
