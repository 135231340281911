/* Override /layout/_footer.scss  */

.layout-footer {
    position: fixed;
    z-index: 100;
    height: $footerBarHeight;
    width: 100%;
    padding: 0 3rem;
    bottom: 0;
    // left: 0;
    color: var(--v-menuitem-text-color);

    .footer-start {
        img {
            width: 5rem;
        }
    }
}
