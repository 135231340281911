.layout-sidebar {
    .layout-menu {
        padding-bottom: 5rem;

        .layout-root-menuitem {
            > .layout-menuitem-root-text {
                display: flex;
                align-items: center;
            }
        }
    }
}
